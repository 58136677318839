<template>
  <div>
    <v-banner class="pb-0">
      <div class="d-flex justify-space-between pr-4 py-3">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Evaluaciones diagnósticas
        </p>
      </div>
    </v-banner>

    <v-container>
      <!-- Buscador de estudiantes-->
      <v-row justify="end">
        <v-col>
          <v-btn
            v-if="searchInArray(actions, 'Agregar')"
            color="primary"
            class="white--text"
            @click="openAddDiagnostic()"
          >
            Agendar evaluación diagnóstica
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="studentSearch"
            :label="students.isLoading ? 'Cargando estudiantes...' : ''"
            placeholder="Buscar estudiante..."
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Contenido -->
      <v-container class="pt-16">
        <v-data-table
          :items="studentDiagnostics.data"
          :headers="studentDiagnostics.headers"
          :loading="studentDiagnostics.isLoading"
          :items-per-page="10"
          single-expand
          item-key="id_diagnostic_test_result"
          :expanded.sync="expanded"
          show-expand
          :custom-sort="customSortForDataTable"
          :search="studentSearch"
          :custom-filter="customSearch"
        >
          <template v-slot:[`item.student`]="{ item }">
            <div>
              {{ `${item.student.first_name} ${item.student.last_name}` }}
            </div>
          </template>
          <!-- Fecha de la evaluación -->
          <template v-slot:[`item.diagnostic_date`]="{ item }">
            <v-badge
              v-if="EmptyDiagnosticOnPastDate(item)"
              color="red"
            >
              <template v-slot:badge>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="white">
                      mdi-exclamation-thick
                    </v-icon>
                  </template>
                  <span> Evaluación retrasada </span>
                </v-tooltip>
              </template>
              <div>
                {{ item.diagnostic_date_formatted }}
              </div>
            </v-badge>
            <v-badge
              v-else-if="EmptyDiagnosticAndTodayEvaluation(item)"
              color="orange"
            >
              <template v-slot:badge>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="white">
                      mdi-exclamation-thick
                    </v-icon>
                  </template>
                  <span> Evalución agendada para hoy </span>
                </v-tooltip>
              </template>
              <div>
                {{ item.diagnostic_date_formatted }}
              </div>
            </v-badge>
            <div v-else>
              {{ item.diagnostic_date_formatted }}
            </div>
          </template>
          <!-- Detalles -->
          <template v-slot:[`item.data-table-expand`]="{ item }">
            <BadgetWithTooltip
              :badgetColor="'blue'"
              v-if="EmptyDiagnosticOAvailableDate(item)"
              :tooltipText="'Sin registro de sugerencia ni evaluador'"
            >
            <!-- Slot con el contenido principal a mostrar -->
              <v-icon :class="rotateIcon(item)" @click="fillExpanded(item)">
                mdi-chevron-up
              </v-icon>
            </BadgetWithTooltip>
            <div v-else>
              <v-icon :class="rotateIcon(item)" @click="fillExpanded(item)">
                mdi-chevron-up
              </v-icon>
            </div>
          </template>
          <!-- Contenido desplegado-->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container class="d-flex flex-column">
                <div>
                  <p>
                    {{
                      item.suggested_level
                        ? item.suggested_level
                        : "Pendiente de ingresar"
                    }}
                  </p>
                  <p>
                    Evaluado por:
                    <span class="font-weight-bold pl-1">{{
                      item.realized_by
                        ? item.realized_by
                        : "Pendiente de ingresar"
                    }}</span>
                  </p>
                </div>
                <div class="d-flex justify-end">
                  <v-btn text color="red darken-2" v-if="searchInArray(actions, 'Eliminar')" @click="deleteDiagnostics(item.id_diagnostic_test_result)">Eliminar</v-btn>
                  <v-btn text color="green darken-2" v-if="searchInArray(actions, 'Editar')" @click="openUpdateDiagnostic(item)">Editar</v-btn>
                  <v-btn text color="blue darken-2"  v-if="!item.suggested_level && searchInArray(actions, 'Agregar')" @click="openRegisterDiagnostic(item)"
                    >Registrar evaluación</v-btn
                  >
                </div>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </v-container>
    <!-- Agregar nuevas evaluaciones -->
    <DiagnosticModal
      title="Agendar evaluación diagnóstica"
      ref="mdlAddDiagnostic"
      @reloadContent="getDiagnosticResults"
    />
    <!-- Editar evaluaciones -->
    <DiagnosticModal
      title="Editar evaluación diagnóstica"
      ref="mdlUpdateDiagnostic"
      @reloadContent="getDiagnosticResults"
    />
    <!-- Registar resultados -->
    <DiagnosticModal
      title="Registrar resultados"
      ref="mdlRegisterDiagnostic"
      @reloadContent="getDiagnosticResults"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import DiagnosticModal from "./DiagnosticsModal.vue";
import BadgetWithTooltip from "../../components/BadgetWithTooltip.vue";

export default {
  name: "DiagnosticResults",
  components: {
    DiagnosticModal,
    BadgetWithTooltip,
  },
  data() {
    return {
      studentSearch: "",
      expanded: [],
      students: {
        isLoading: false,
        data: [],
      },
      studentDiagnostics: {
        isLoading: false,
        headers: [
          {
            text: "Carnet",
            value: "student.code",
          },
          {
            text: "Aspirante",
            value: "student",
          },
          {
            text: "Teléfono",
            value: "student.phone",
          },
          {
            text: "Fecha de evaluación",
            value: "diagnostic_date",
          },
          {
            text: "Hora de evaluación ",
            value: "diagnostic_hour",
          },
          {
            text: "Detalles",
            value: "data-table-expand",
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    ...mapActions({
      getCurrentActions: 'getCurrentActions', // Obtener acciones
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    getStudents() {
      this.students.isLoading = true;
      axios
        .get(`${this.baseURL}student/all`)
        .then(({ data }) => {
          this.students.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los estudiantes",
          });
        })
        .finally(() => {
          this.students.isLoading = false;
        });
    },
    getDiagnosticResults() {
      this.studentDiagnostics.isLoading = true;
      axios
        .get(`${this.baseURL}diagnostic-test-results/all`)
        .then(({ data }) => {
          this.studentDiagnostics.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los resultados",
          });
        })
        .finally(() => {
          this.studentDiagnostics.isLoading = false;
        });
    },
    formatHour(stringTime) {
      const exampleDate = new Date();
      const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
      const timeSplited = stringTime.split(":");
      exampleDate.setHours(timeSplited[0], timeSplited[1], timeSplited[2]);
      const formatTime = exampleDate.toLocaleTimeString("es-SV", timeOptions);
      return formatTime;
    },
    setRowColor(diagnosticInfo) {
      const evaluationDate = new Date(diagnosticInfo.diagnostic_date);

      if (!diagnosticInfo.suggested_level && evaluationDate > new Date()) {
        return "orange lighten-5";
      }
      if (!diagnosticInfo.suggested_level && evaluationDate < new Date()) {
        return "red lighten-5";
      }
    },
    setBadgeColor(diagnosticInfo) {
      const evaluationDate = new Date(diagnosticInfo.diagnostic_date);

      if (!diagnosticInfo.suggested_level && evaluationDate > new Date()) {
        return "orange";
      }
      if (!diagnosticInfo.suggested_level && evaluationDate < new Date()) {
        return "red";
      }
    },
    customSortForDataTable(items) {
      items.sort((a, b) => {
        // Registros que vencidos y no registrados
        if (new Date(a.diagnostic_date) < new Date() && !a.suggested_level) {
          return -1;
        }
        // Registros no vencidos y no registrados
        if (new Date(a.diagnostic_date) >= new Date() && !a.suggested_level) {
          return -1;
        }
      });

      return items;
    },
    customSearch(value, search, item) {
      const searchValue = search.toLowerCase();
      const fullName = `${item.student.first_name} ${item.student.last_name}`.toLowerCase();
      const code = item.student.code;
      const phone = item.student.phone;
      const diagnosticDate = item.diagnostic_date;
      const diagnosticHour = item.diagnostic_hour;
      return fullName.includes(searchValue) || code.includes(searchValue) 
      || phone.includes(searchValue) || diagnosticDate.includes(searchValue) 
      || diagnosticHour.includes(searchValue);
    },
    fillExpanded(item) {
      if (this.expanded.length == 0) {
        this.expanded.push(item);
        return;
      }
      this.expanded = [];
    },
    EmptyDiagnosticOnPastDate(item) {
      const evaluationDate = new Date(item.diagnostic_date).toISOString().split('T')[0];
      const currentDate = new Date().toISOString().split('T')[0];

      return evaluationDate < currentDate && (item.suggested_level == null || item.suggested_level == "");
    },
    EmptyDiagnosticOAvailableDate(item) {
      const evaluationDate = new Date(item.diagnostic_date).toISOString().split('T')[0];
      const currentDate = new Date().toISOString().split('T')[0];

      return evaluationDate > currentDate && (item.suggested_level == null || item.suggested_level == "");
    },
    EmptyDiagnosticAndTodayEvaluation(item) {
      const evaluationDate = new Date(item.diagnostic_date).toISOString().split('T')[0];
      const currentDate = new Date().toISOString().split('T')[0];

      return evaluationDate == currentDate && (item.suggested_level == null || item.suggested_level == "");
    },
    rotateIcon(item) {
      if (
        this.expanded.length !== 0 &&
        this.expanded[0].id_diagnostic_test_result ===
          item.id_diagnostic_test_result
      ) {
        return "";
      }
      return "rotate-icon";
    },
    openAddDiagnostic() {
      this.$refs.mdlAddDiagnostic.openModal(null, "Add");
    },
    openUpdateDiagnostic(completeEvaluation) {
      this.$refs.mdlUpdateDiagnostic.openModal(completeEvaluation, "Edit");
    },
    openRegisterDiagnostic(completeEvaluation) {
      this.$refs.mdlRegisterDiagnostic.openModal(completeEvaluation, "Register");
    },
    deleteDiagnostics(idDiagnosticResult) {
      this.$swal({
        title: "Eliminar evaluación diagnóstica",
        html: "Estás a punto de eliminar una evaluación <br>¿Deseas continuar?",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              `${this.baseURL}diagnostic-test-results/${idDiagnosticResult}`
            )
            .then(({ data }) => {
              if (data?.response) {
                this.openSnackbar({
                  type: "success",
                  message: "Evaluación eliminada correctamente",
                });
                // Recargar datos
                this.getDiagnosticResults();
                return;
              }
              this.openSnackbar({
                type: "danger",
                message: data?.message,
              });
            })
            .catch((_err) => {
              this.openSnackbar({
                type: "danger",
                message: err,
              });
            });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      baseURL: "getApi",
      actions: 'getCurrentActions',
    }),
  },
  mounted() {
    this.getStudents();
    this.getDiagnosticResults();
    this.getCurrentActions('Evaluaciones diagnósticas');
  },
};
</script>

<style>
.rotate-icon {
  transition: transform 0.5s ease-in-out;
  transform: rotate(-180deg);
}
</style>