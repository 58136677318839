<template>
  <div>
    <v-badge
      :color="badgetColor"
    >
      <template v-slot:badge>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="white">
              {{ iconText }}
            </v-icon>
          </template>
          <span> {{ tooltipText }} </span>
        </v-tooltip>
      </template>
      <div>
        <slot></slot>
      </div>
    </v-badge>
  </div>
</template>

<script>
export default {
    name: "BadgetWithTooltip",
    props: {
        badgetColor: {
            type: String,
            default: "primary"
        },
        iconText: {
            type: String,
            default: "mdi-exclamation-thick"
        },
        tooltipText: {
            type: String,
            required: true
        }
    }
};
</script>