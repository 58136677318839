<template>
  <div>
    <v-dialog v-model="showModal" max-width="600">
      <v-card>
        <v-card-title class="pb-7"> {{ title }} </v-card-title>
        <v-card-text>
          <v-row v-if="mode !== 'Register'">
            <v-col cols="12" class="py-0">
              <v-autocomplete
                :label="students.isLoading ? 'Cargando estudiantes...' : ''"
                placeholder="Buscar estudiante..."
                v-model="form.id_student"
                :items="students.data"
                :error-messages="studentErrors"
                item-text="id_student"
                item-value="id_student"
                open-on-clear
                outlined
                clearable
                :filter="customFilter"
              >
                <template v-slot:selection="{ item }">
                  <div>
                    <span>
                      {{
                        `${item.first_name} ${item.last_name} - ${item.code}`
                      }}
                    </span>
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div>
                    <span>
                      {{
                        `${item.first_name} ${item.last_name} - ${item.code}`
                      }}
                    </span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="12" md="6" lg="6" class="py-0">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :nudge-right="40"
                v-model="showCalendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.diagnostic_date"
                    label="Fecha de evaluación"
                    :error-messages="diagnosticDateErrors"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.diagnostic_date"
                  @input="showCalendar = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col sm="12" md="6" lg="6" class="py-0">
              <v-menu
                ref="timerMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :nudge-right="40"
                :return-value.sync="form.diagnostic_hour"
                v-model="showTimer"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.diagnostic_hour"
                    label="Hora de evaluación"
                    :error-messages="diagnosticTimeErrors"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  scrollable
                  v-model="form.diagnostic_hour"
                  @click:minute="$refs.timerMenu.save(form.diagnostic_hour)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-container class="px-0 py-0" fluid>
                <v-switch
                  class="py-0 my-0"
                  v-model="extendedForm"
                  v-if="mode === 'Add'"
                  :label="`¿Desea llenar el contenido de la evaluación? ${extendedForm ? 'Si' : 'No'}`"
                ></v-switch>
              </v-container>
            </v-col>
            <v-col cols="12" class="py-0" v-if="extendedForm">
              <v-textarea
                label="Nivel sugerido"
                outlined
                v-model="form.suggested_level"
                :error-messages="suggestedLevelErrors"
              >
              </v-textarea>
              <v-text-field
                :error-messages="userErrors"
                label="Evaluador por"
                outlined
                v-model="form.realized_by"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn text @click="closeModal()">Cancelar</v-btn>
              <v-btn
                v-if="mode === 'Add'"
                color="green darken-2"
                text
                @click="sendForm()"
              >
                Guardar
              </v-btn>
              <v-btn v-if="mode === 'Edit'" color="orange darken-2" text @click="updateForm()">
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="mode === 'Register'">
            <v-col cols="12">
              <v-textarea
                label="Nivel sugerido"
                outlined
                v-model="form.suggested_level"
                :error-messages="suggestedLevelErrors"
              >
              </v-textarea>
              <v-text-field
                :error-messages="userErrors"
                label="Evaluador por"
                outlined
                v-model="form.realized_by"
              />
            </v-col>
            <v-col cols="12">
              <v-btn text @click="closeModal()">Cancelar</v-btn>
              <v-btn color="blue darken-2" text @click="updateForm()">
                Registrar resultado
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { requiredIf, maxLength } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DiagnosticsModal",
  data() {
    return {
      showModal: false,
      showCalendar: false,
      showTimer: false,
      extendedForm: false,
      studentId: null,
      mode: "",
      idDiagnosticResult: null,
      students: {
        data: [],
        isLoading: false,
      },
      form: {
        diagnostic_date: null,
        diagnostic_hour: null,
        id_student: null,
        suggested_level: "",
        realized_by: ""
      },
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    openModal(values, mode) {
      this.showModal = true;
      this.mode = mode;

      // Si es un objeto y está completo, modo editar
      if (mode == "Edit") {
        // Se cargan los datos
        Object.keys(this.form).forEach((key) => {
          if (key === "id_student") {
            this.form[key] = values.student.id_student;
            return;
          }
          this.form[key] = values[key];
        });
        this.idDiagnosticResult = values.id_diagnostic_test_result;
        // Ahora todos los campos serán obligatorios
        this.extendedForm = true;
        return;
      }

      if (mode == "Register") {
        // Se cargan los datos menos el usuario y el nivel sugerido
        Object.keys(this.form).forEach((key) => {
          if (['suggested_level', 'realized_by'].includes(key)) {
            this.form[key] = null;
            return;
          }
          if (key === "id_student") {
            this.form[key] = values?.student.id_student;
            return;
          }
          this.form[key] = values[key];
        });
        this.idDiagnosticResult = values.id_diagnostic_test_result;
        return;
      }
      // No se muestra los campos extras para agregar o registrar (Vista aparte para este caso)
      this.extendedForm = false;
    },
    closeModal() {
      this.showModal = false;
      this.form = {
        diagnostic_date: null,
        diagnostic_hour: null,
        id_student: null,
        suggested_level: "",
        realized_by: ""
      };
      this.extendedForm = false;
      this.$v.form.$reset();
    },
    getStudents() {
      this.students.isLoading = true;
      axios
        .get(`${this.baseURL}student/all`)
        .then(({ data }) => {
          this.students.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los estudiantes",
          });
        })
        .finally(() => {
          this.students.isLoading = false;
        });
    },
    sendForm() {
      // Pasa como nulo si el valor está vacío.
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] == "") {
          this.form[key] = null;
        }
      })
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.openSnackbar({
          type: "warning",
          message: "Revisa los campos antes de continuar",
        });
        return;
      }
      // Elimina o no los campos dependiendo del estado del formulario extendido
      if (!this.extendedForm) {
        delete this.form.suggested_level;
        delete this.form.realized_by;
      }
      axios
        .post(`${this.baseURL}diagnostic-test-results`, this.form)
        .then(({ data }) => {
          if (data?.response) {
            this.openSnackbar({
              type: "success",
              message: "Evaluación guardada correctamente",
            });
            this.closeModal();
            // Recargar contenido
            this.$emit("reloadContent");
            return;
          }
          const errorMessage = data?.message ? data.message : "Ocurrió un error"
          this.openSnackbar({
            type: "danger",
            message: errorMessage,
          });
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: err,
          });
        });
    },
    updateForm() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.openSnackbar({
          type: "warning",
          message: "Revisa los campos antes de continuar",
        });
        return;
      }
      axios
        .put(
          `${this.baseURL}diagnostic-test-results/${this.idDiagnosticResult}`,
          this.form
        )
        .then(({ data }) => {
          if (data?.response) {
            this.openSnackbar({
              type: "success",
              message: "Evaluación actualizada correctamente",
            });
            this.closeModal();
            // Recargar contenido
            this.$emit("reloadContent");
            return;
          }
          this.openSnackbar({
            type: "error",
            message: data?.message,
          });
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: err,
          });
        });
    },
    customFilter(item, queryText) {
      const searchText = queryText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const codeMatch = item.code.toString().includes(searchText);
      const firstName = item.first_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const lastName = item.last_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const fullName = `${firstName} ${lastName}`.toString();
      return (
        codeMatch ||
        firstName.includes(searchText) ||
        lastName.includes(searchText) ||
        fullName.includes(searchText)
      );
    },
  },
  computed: {
    ...mapGetters({
      baseURL: "getApi",
      actions: "getCurrentActions",
    }),
    diagnosticTimeErrors() {
      const errors = [];
      if (!this.$v.form.diagnostic_hour.$dirty) return errors;
      // Errors
      if (!this.$v.form.diagnostic_hour.required)
        errors.push("Campo requerido");

      return errors;
    },
    diagnosticDateErrors() {
      const errors = [];
      if (!this.$v.form.diagnostic_date.$dirty) return errors;
      // Errors
      if (!this.$v.form.diagnostic_date.required)
        errors.push("Campo requerido");

      return errors;
    },
    studentErrors() {
      const errors = [];
      if (!this.$v.form.id_student.$dirty) return errors;
      // Errors
      if (!this.$v.form.id_student.required)
        errors.push("Campo requerido");

      return errors;
    },
    suggestedLevelErrors() {
      const errors = [];
      if (!this.$v.form.suggested_level.$dirty) return errors;
      // Errors
      if (!this.$v.form.suggested_level.required)
        errors.push("Campo requerido");
      if (!this.$v.form.suggested_level.maxLength)
        errors.push("El campo no debe de superar los 500 caracteres")

      return errors;
    },
    userErrors() {
      const errors = [];
      if (!this.$v.form.realized_by.$dirty) return errors;

      if (!this.$v.form.realized_by.required) errors.push("Campo requerido");

      return errors;
    },
    addOrEdit() {
      return this.mode === "Add";
    }
  },
  mounted() {
    this.getStudents();
  },
  validations: {
    form: {
      diagnostic_date: {
        required: requiredIf(function() {
          return this.addOrEdit;
        })
      },
      diagnostic_hour: {
        required: requiredIf(function() {
          return this.addOrEdit;
        })
      },
      id_student: {
        required: requiredIf(function() {
          return this.addOrEdit;
        })
      },
      suggested_level: {
        required: requiredIf(function() {
          return this.extendedForm && this.addOrEdit;
        }),
        maxLength: maxLength(500)
      },
      realized_by: {
        required: requiredIf(function() {
          return this.extendedForm && this.addOrEdit;
        }),
      }
    },
  },
};
</script>